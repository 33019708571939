import styled from "styled-components";
import { Stream } from "@cloudflare/stream-react";
import { FaTimes } from "react-icons/fa";
import { Modal } from "./Modal";

const TopMenu = styled.div`
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 4px;
`;

type Props = {
  onClose: () => void;
  token: string;
};

export function VideoDisplay({ onClose, token }: Props) {
  return (
    <Modal isVisible={true} backgroundColor={"#000000DD"}>
      <>
        <TopMenu>
          <FaTimes
            color={"#FFF"}
            size={26}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          />
        </TopMenu>
        <Stream src={token} controls={true} />
      </>
    </Modal>
  );
}
