import create from "zustand";
import { DialogType } from "../types";

type TmpStore = {
  globalDialog: DialogType | null;
  setGlobalDialog: (error: DialogType | null) => void;
};

export const useTmpStore = create<TmpStore>((set, get) => ({
  globalDialog: null,
  setGlobalDialog: (error: DialogType | null) =>
    set((state) => ({ globalDialog: error })),
}));
