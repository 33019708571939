import { useSignMessage } from "wagmi";
import { ChallengeCreateResponse, WatchVideoParams } from "../types";
import { useState } from "react";
import generateAPI from "../utils/api";

const api = generateAPI();

export function useSignVideo(videoId: string) {
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const reset = () => setVideoUrl(null);

  async function getVideo(watchVideoParams: WatchVideoParams) {
    const result = await api.video.watch.post(watchVideoParams);

    if (result.token) {
      const token = result.token;

      setVideoUrl(token);
    }
  }

  const { signMessage } = useSignMessage({
    onSuccess: async (signature, signedMessageArgs) => {
      const watchVideoParams: WatchVideoParams = {
        videoId: videoId,
        signedDataObject: {
          signature,
          signedMessageArgs: signedMessageArgs,
        },
      };

      await getVideo(watchVideoParams);
    },
  });

  async function makeAndSignChallenge(dontSign = false) {
    const challengeCreateResponse =
      (await api.challenge.create.get()) as ChallengeCreateResponse;

    if (dontSign) {
      await getVideo({ videoId: videoId, signedDataObject: null });
    } else {
      signMessage({
        message: `Sign message to prove NFT ownership: ${challengeCreateResponse.challenge.challengeToken}`,
      });
    }
  }
  return { makeAndSignChallenge, videoUrl, reset };
}
