import React from "react";
import "../App.css";
import { FaUnlock } from "react-icons/fa";
import styled from "styled-components";
import { Header } from "../Header";
import { Flex } from "../styles/Flex";
import { WhiteButton } from "../styles/WhiteButton";
import { ScrollSection } from "../components/ScrollSection";
import { Footer } from "../components/Footer";
import { useSignVideo } from "../hooks/useSignVideo";
import { VideoDisplay } from "../components/VideoDisplay";
import { Dialog } from "../components/Dialog";
import { useTmpStore } from "../hooks/useTmpStore";
import { useWelcomeDialog } from "../hooks/useWelcomeDialog";

const Container = styled.div`
  padding-bottom: 40px;
`;

const Inner = styled.div`
  padding: 0 80px;

  @media only screen and (max-width: 600px) {
    display: block;
    padding: 0 30px;
  }
`;

const Title = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Subtitle = styled(Flex)`
  color: #ccc;
  font-size: 18px;
`;

const Description = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  width: 40%;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 45%);

  @media only screen and (max-width: 600px) {
    width: auto;
  }
`;

const BillBoard = styled.div`
  height: 56.25vw;
  background-image: url("/images/boneyard-fullscreen.jpg");
  background-size: cover;
  box-shadow: inset 0 -10em 20em rgb(0 0 0);
`;

const UnlockIcon = styled(FaUnlock)`
  margin-right: 8px;
`;

const WatchNowButton = styled(WhiteButton)`
  margin-top: 30px;
  padding: 14px 40px;
  border-radius: 30px;
`;

const ScrollBlocks = styled.div`
  margin-top: 100px;
`;

function HomeScreen() {
  const globalDialog = useTmpStore((store) => store.globalDialog);
  const setGlobalDialog = useTmpStore((store) => store.setGlobalDialog);

  const { makeAndSignChallenge, videoUrl, reset } = useSignVideo(
    "6298cb5632ed45c76183efc1"
  );

  useWelcomeDialog();

  return (
    <Container>
      {videoUrl && <VideoDisplay token={videoUrl} onClose={() => reset()} />}

      {globalDialog && (
        <Dialog
          width={500}
          body={<div>{globalDialog.body}</div>}
          onClose={() => setGlobalDialog(null)}
          isVisible={true}
        />
      )}

      <BillBoard>
        <Header />
        <Inner>
          <Subtitle>
            <UnlockIcon size={20} color={"#ccc"} />
            Episode 1
          </Subtitle>
          <Title>Jay Pegs Auto Mart</Title>
          <Description>
            The legend of Jay Pegs Auto Mart, "the world's #1 2007 Kia Sedona
            superstore". A story about quality, gang violence, sales, magic,
            paperwork, puppets, and a truly reliable vehicle.
          </Description>

          <WatchNowButton
            onClick={async () => {
              await makeAndSignChallenge(true);
            }}
          >
            Watch Now
          </WatchNowButton>
        </Inner>
        <ScrollBlocks>
          <ScrollSection releaseId={"629e1a16c8dcf88631fe812c"} />
          <ScrollSection releaseId={"6298cb3832ed45c76183efa3"} />
          <ScrollSection releaseId={"6298d1c732ed45c76183f109"} />
          <ScrollSection releaseId={"629907454325ebd9b6b6d950"} />
          <Footer />
        </ScrollBlocks>
      </BillBoard>
    </Container>
  );
}

export default HomeScreen;
