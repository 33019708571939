import { useAccount, useContractRead } from "wagmi";
import { erc721ABI } from "wagmi";
import { BigNumber } from "ethers";
import { ethers } from "ethers";

type Props = {
  contractAddress: string | null;
  tokenIds?: number[];
};

export function useNftOwnershipInfo({ contractAddress, tokenIds }: Props) {
  const { data: accountData } = useAccount();

  const { data, isLoading } = useContractRead(
    {
      addressOrName: contractAddress ?? "",
      contractInterface: erc721ABI,
    },
    "balanceOf",
    {
      args: accountData?.address,
      enabled: !!accountData?.address && !!contractAddress,
    }
  );

  if (!contractAddress) {
    return { isLoading, data: ethers.BigNumber.from(0) };
  }

  return {
    data: data ? (data as unknown as BigNumber) : ethers.BigNumber.from(0),
    isLoading,
  };
}
