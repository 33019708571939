import React from "react";
import "../App.css";
import { FaUnlock } from "react-icons/fa";
import styled from "styled-components";
import { Header } from "../Header";
import { Flex } from "../styles/Flex";
import { WhiteButton } from "../styles/WhiteButton";
import { ScrollSection } from "./ScrollSection";
import { Footer } from "./Footer";
import { useSignVideo } from "../hooks/useSignVideo";
import { VideoDisplay } from "./VideoDisplay";
import { Dialog } from "./Dialog";
import { useTmpStore } from "../hooks/useTmpStore";
import { useWelcomeDialog } from "../hooks/useWelcomeDialog";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

const Container = styled.div`
  padding-bottom: 40px;
`;

const Inner = styled.div`
  padding: 0 80px;

  @media only screen and (max-width: 600px) {
    display: block;
    padding: 0 40px;
  }
`;

const Title = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Subtitle = styled(Flex)`
  color: #ccc;
  font-size: 18px;
`;

const Description = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  width: 40%;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 45%);

  @media only screen and (max-width: 600px) {
    width: auto;
  }
`;

type WithUrl = {
  url: string;
};

const BillBoard = styled.div<WithUrl>`
  height: 56.25vw;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  box-shadow: inset 0 -10em 20em rgb(0 0 0);

  @media only screen and (max-width: 600px) {
    height: 100vh;
    background-position: center;
  }
`;

const UnlockIcon = styled(FaUnlock)`
  margin-right: 8px;
`;

const WatchNowButton = styled(WhiteButton)`
  margin-top: 30px;
  padding: 14px 40px;
  border-radius: 30px;
`;

const ScrollBlocks = styled.div`
  margin-top: 100px;
`;

type BrandItem = {
  slug: string;
  backgroundUrl: string;
  logoUrl: string;
  title: string;
  description: string;
  featureVideo: string;
  seriesArr: string[];
};

const brands: BrandItem[] = [
  {
    slug: "jaypegsautomart",
    backgroundUrl: "/images/boneyard-fullscreen.jpg",
    logoUrl: "/images/jpegs-logo.png",
    featureVideo: "6298cb5632ed45c76183efc1",
    description:
      "The legend of Jay Pegs Auto Mart, \"the world's #1 2007 Kia Sedona\n" +
      '            superstore". A story about quality, gang violence, sales, magic,\n' +
      "            paperwork, puppets, and a truly reliable vehicle.",
    seriesArr: ["6298cb3832ed45c76183efa3"],
    title: "Jay Pegs Auto Mart",
  },
  {
    slug: "shadowlion",
    backgroundUrl: "/images/madeforthis_fullscreen.jpg",
    logoUrl: "/images/shadowlion-logo.png",
    description:
      "Z: Made For This is a four episode docuseries profiling Zdeno Chara through their 2019 Stanley Cup Final run and subsequent 2020 season, including the unprecedented pause due to COVID-19 and his transition from Boston to Washington DC.",
    seriesArr: ["629e1a16c8dcf88631fe812c"],
    featureVideo: "629e1a65c8dcf88631fe8142",
    title: "Z: Made For This",
  },
];

function HomeScreen() {
  const { brandSlug } = useParams();

  const selectedBrand = brands.find((brand) => brand.slug === brandSlug);

  invariant(selectedBrand);

  const globalDialog = useTmpStore((store) => store.globalDialog);
  const setGlobalDialog = useTmpStore((store) => store.setGlobalDialog);

  const { makeAndSignChallenge, videoUrl, reset } = useSignVideo(
    selectedBrand.featureVideo
  );

  useWelcomeDialog();

  return (
    <Container>
      {videoUrl && <VideoDisplay token={videoUrl} onClose={() => reset()} />}

      {globalDialog && (
        <Dialog
          width={500}
          body={<div>{globalDialog.body}</div>}
          onClose={() => setGlobalDialog(null)}
          isVisible={true}
        />
      )}

      <BillBoard url={selectedBrand.backgroundUrl}>
        <Header logo={selectedBrand.logoUrl} />
        <Inner>
          <Subtitle>
            <UnlockIcon size={20} color={"#ccc"} />
            Episode 1
          </Subtitle>
          <Title>{selectedBrand.title}</Title>
          <Description>{selectedBrand.description}</Description>

          <WatchNowButton
            onClick={async () => {
              await makeAndSignChallenge(true);
            }}
          >
            Watch Now
          </WatchNowButton>
        </Inner>
        <ScrollBlocks>
          {selectedBrand.seriesArr.map((seriedId) => (
            <ScrollSection releaseId={seriedId} />
          ))}

          <Footer />
        </ScrollBlocks>
      </BillBoard>
    </Container>
  );
}

export default HomeScreen;
