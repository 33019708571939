import styled from "styled-components";
import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Card } from "./Card";
import { VideoListResponse } from "../types";
import { useQuery } from "react-query";
import generateAPI from "../utils/api";
import invariant from "tiny-invariant";

const Container = styled.div`
  margin-top: 40px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 26px;
  color: #fff;
  margin-left: 80px;
  margin-bottom: 20px;

  @media only screen and (max-width: 600px) {
    margin-left: 40px;
    margin-bottom: 30px;
  }
`;

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

type Props = {
  releaseId: string;
};

export function ScrollSection({ releaseId }: Props) {
  const api = generateAPI();

  const { data, isLoading } = useQuery<VideoListResponse>(
    ["release", releaseId],
    () => api.video.listForReleasePublic[releaseId].get()
  );

  if (isLoading || !data || !data.videos) return null;

  const release = data.videos[0].release;
  invariant(typeof release !== "string");
  const production = release.production;
  invariant(typeof production !== "string");

  return (
    <Container>
      <Title>{production.name}</Title>
      <div>
        <ScrollMenu onWheel={onWheel}>
          {data.videos.map((video, i) => (
            <Card key={i} itemId={i} video={video} />
          ))}
        </ScrollMenu>
      </div>
    </Container>
  );
}
