import styled from "styled-components";
import { Flex } from "./styles/Flex";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const Container = styled(Flex)`
  justify-content: space-between;
  margin: 0 auto;
  padding: 60px 80px;

  @media only screen and (max-width: 600px) {
    display: block;
    padding: 40px 40px;
  }
`;

const Left = styled(Flex)`
  @media only screen and (max-width: 600px) {
    display: block;
    margin-top: 10px;
  }
`;
const Right = styled.div``;

type Selectable = {
  selected?: boolean;
};

const MenuItem = styled.a<Selectable>`
  color: ${(props) => (props.selected ? "#FFF" : "#999")};
  font-weight: bold;
  font-size: 18px;
  padding: 20px;
  cursor: pointer; 
  @media only screen and (max-width: 600px) {
    visibility: hidden;
  }
`;

const Logo = styled.img`
  width: 180px;
  margin-right: 40px;

  @media only screen and (max-width: 600px) {
    width: 80px;
  }
`;

type Props = {
  logo?: string;
};

export function Header({ logo }: Props) {
  return (
    <Container>
      <Left>
        <Logo src={logo ?? "/images/logo2.png"} />
        <MenuItem selected={true}>Series</MenuItem>
        <MenuItem>Films</MenuItem>
        <MenuItem>Auctions</MenuItem>
      </Left>
      <Right>
        <ConnectButton />
        {/*<WhiteButton>*/}
        {/*  <WalletCheckIcon size={18} color={"#000"} /> Wallet Connected*/}
        {/*</WhiteButton>*/}
      </Right>
    </Container>
  );
}
