import { serverURL } from "./utils";

export default function generateAPI() {
  return generateAPIBase(serverURL, {
    headers: { "Content-Type": "application/json" },
  });
}

function generateAPIBase(
  baseUrl: string,
  defaults = {},
  scope: any[] = []
): any {
  const callable = () => {};
  callable.url = baseUrl;
  return new Proxy(callable, {
    get({ url }, propKey: any) {
      const method = propKey.toUpperCase();
      const path = scope.concat(propKey);
      if (["GET", "POST", "PUT", "DELETE", "PATCH"].includes(method)) {
        return (data: string, overrides = {}) => {
          const payload = {
            method,
            ...defaults,
            ...overrides,
          };
          switch (method) {
            case "GET": {
              if (data) url = `${url}?${new URLSearchParams(data)}`;
              break;
            }
            case "POST":
            case "PUT":
            case "PATCH": {
              // @ts-ignore
              payload.body = JSON.stringify(data);
            }
          }
          return fetch(url, payload).then((d) => d.json());
        };
      }
      return generateAPIBase(`${url}/${propKey}`, defaults, path);
    },
    apply({ url }, thisArg, [arg] = []) {
      const path = url.split("/");
      return generateAPIBase(arg ? `${url}/${arg}` : url, defaults, path);
    },
  });
}
