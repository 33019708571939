import React, { MouseEventHandler, ReactElement } from "react";
import styled from "styled-components";

interface props {
  onClose?: MouseEventHandler;
  children: ReactElement;
  isVisible: boolean;
  backgroundColor?: string;
  width?: number;
  style?: object;
}

const Container = styled.div`
  position: fixed;
  border-radius: 4px;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 94%;
  z-index: 10000;
  box-shadow: 0 0 14px hsla(0, 0%, 0%, 0.2);

  @media only screen and (max-width: 600px) {
    position: fixed;
  }
`;

type BgType = {
  backgroundColor?: string;
};

const Background = styled.div<BgType>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.backgroundColor ?? "#00000033"};
  z-index: 5000;
`;

export function Modal({
  onClose,
  children,
  isVisible,
  style,
  backgroundColor,
}: props) {
  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <Background onClick={onClose} backgroundColor={backgroundColor} />
      <Container style={style}>{children}</Container>
    </div>
  );
}
