import create from "zustand";
import { persist } from "zustand/middleware";
import { EthSignedDataObject } from "../types";

type Store = {
  token: EthSignedDataObject | null;
  hasSeenWelcome: boolean;
  setToken: (token: EthSignedDataObject) => void;
  setHasSeenWelcome: (val: boolean) => void;
};

export const useStore = create<Store>(
  persist(
    (set, get) => ({
      hasSeenWelcome: false,
      token: null,
      setHasSeenWelcome: (hasSeenWelcome) =>
        set((state) => ({ hasSeenWelcome })),
      setToken: (token) => set((state) => ({ token })),
    }),
    {
      name: "little-river", // unique name
    }
  )
);
