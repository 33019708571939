import styled from "styled-components";
import {BrowserRouter as Router, Link} from 'react-router-dom';


const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 80px;
  margin: 60px 80px;
  color: #fff;
  font-size: 16px;

  @media only screen and (max-width: 600px) {
    margin: 80px 40px;
  }
`;

const Side = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;

  @media only screen and (max-width: 600px) {
font-size: 12px;
  }
`;

const Side2 = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;

  @media only screen and (max-width: 600px) {
visibility: hidden;
  }
`;

const Logo = styled.img`
  width: 80px;
  margin-right: 0px;
  margin-left: 10px;
  margin-top: 5px;

  @media only screen and (max-width: 600px) {
    width: 90px;
    float: left;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

type Props = {
  logo?: string;
};

export function Footer() {
  return (
    <Container>
      <Side>
        <b>Brought to you by:</b>
        <a href="https://www.nftv.video/" target="_blank" rel="noreferrer">
          <Logo src={"/images/logo3.png"} alt="example" />
        </a>
      </Side>
      <Side2>Non-fungible Television</Side2>
    </Container>
  );
}
