import React, { useEffect } from "react";
import styled from "styled-components";
import { WhiteButton } from "../styles/WhiteButton";
import { useStore } from "./useStore";
import { useTmpStore } from "./useTmpStore";

const DialogImage = styled.img`
  width: 250px;
  text-align: center;
`;

const LockImageBlock = styled.div`
  margin-bottom: 22px;
  text-align: center;
`;

const ErrorContent = styled.div`
  text-align: center;
`;

const ErrorButtons = styled.div`
  margin-top: 30px;
  justify-content: center;
  align-items: center;
`;

const WhiteButtonWithBorder = styled(WhiteButton)`
  border: 2px solid #000;
  padding: 10px 30px;
  margin: 0 12px;
  border-radius: 30px;
`;

export function useWelcomeDialog() {
  const setGlobalDialog = useTmpStore((store) => store.setGlobalDialog);
  const hasSeenWelcome = useStore((store) => store.hasSeenWelcome);
  const setHasSeenWelcome = useStore((store) => store.setHasSeenWelcome);

  useEffect(() => {
    if (hasSeenWelcome) {
      return;
    }

    setGlobalDialog({
      body: (
        <ErrorContent>
          <LockImageBlock>
            <DialogImage src={"/images/welcome.png"} />
          </LockImageBlock>
          This is a token-gated video streaming platform.{" "}
          <b>Viewing certain videos requires ownership of certain NFTs.</b>{" "}
          Connect your wallet to get started.
          <ErrorButtons>
            <WhiteButtonWithBorder
              inverted={true}
              onClick={() => {
                setHasSeenWelcome(true);
                setGlobalDialog(null);
              }}
            >
              Okay
            </WhiteButtonWithBorder>
          </ErrorButtons>
        </ErrorContent>
      ),
    });
  }, []);
}
