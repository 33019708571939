import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "./HomeScreen";
import BrandSlug from "./components/BrandSlug";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/:brandSlug" element={<BrandSlug />} />
      </Routes>
    </BrowserRouter>
  );
}
