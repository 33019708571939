import React, { CSSProperties, MouseEventHandler } from "react";
import { Modal } from "./Modal";
import styled from "styled-components";

type Props = {
  isVisible?: boolean;
  body: JSX.Element;
  onClose: MouseEventHandler;
  noPadding?: boolean;
  positionTop?: number;
  style?: CSSProperties;
  width?: number;
};

type ContainerProps = {
  width?: number;
};

const Container = styled.div<ContainerProps>`
  max-width: ${(props) => `${props.width}px` ?? "100%"};
  margin: 0 auto;
  border-radius: 34px;
  background-color: #fff;
  padding: 20px;
`;

export function Dialog({
  isVisible,
  body,
  onClose,
  noPadding,
  style,
  width,
}: Props) {
  if (!isVisible) {
    return null;
  }

  const styles = {
    body: {
      padding: noPadding ? 0 : 34,
    },
    xIcon: {
      cursor: "pointer",
    },
    title: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  } as const;

  return (
    <Modal onClose={onClose} isVisible={isVisible} style={style} width={width}>
      <Container width={width}>
        <div style={styles.body}>{body}</div>
      </Container>
    </Modal>
  );
}
