import styled from "styled-components";

type WhiteButtonProps = {
  inverted?: boolean;
};

export const WhiteButton = styled.a<WhiteButtonProps>`
  display: inline-flex;
  background-color: ${(props) => (props.inverted ? "#000" : "#FFF")};
  border-radius: 20px;
  color: ${(props) => (props.inverted ? "#FFF" : "#000")};
  font-weight: bold;
  padding: 8px 18px;
  text-decoration: none;
  cursor: pointer;

  :hover {
    background-color: #000;
    color: #fff;
  }
`;
