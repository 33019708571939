import styled from "styled-components";
import { VideoType } from "../types";
import { VideoDisplay } from "./VideoDisplay";
import { serverURL } from "../utils/utils";
import { FaLock, FaUnlock } from "react-icons/fa";
import { useNftOwnershipInfo } from "../hooks/useNftOwnershipInfo";
import { useSignVideo } from "../hooks/useSignVideo";
import { WhiteButton } from "../styles/WhiteButton";
import { FiArrowUpRight } from "react-icons/fi";
import { useTmpStore } from "../hooks/useTmpStore";
import invariant from "tiny-invariant";

type CardType = {
  imageURL: string;
};

const CardContainer = styled.div<CardType>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  box-shadow: inset 0 -5em 5em rgb(0 0 0 / 40%);
  border-radius: 25px;
  padding: 25px;
  width: 300px;
  height: 200px;
  margin: 0 20px;
  background: url(${(props) => props.imageURL});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid #000;

  :hover {
    border: 2px solid #fff;
  }

  @media only screen and (max-width: 600px) {
    width: 250px;
    height: 150px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: bold;
  color: #fff;
`;

const Button = styled(WhiteButton)`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
`;

const Icon = styled(FiArrowUpRight)`
  margin-left: 4px;
`;

const LockImage = styled.img`
  width: 150px;
  height: 150px;
  text-align: center;
`;

const LockImageBlock = styled.div`
  margin-bottom: 22px;
  text-align: center;
`;

const ErrorContent = styled.div`
  text-align: center;
`;

const ErrorButtons = styled.div`
  margin-top: 30px;
  justify-content: center;
  align-items: center;
`;

const WhiteButtonWithBorder = styled(WhiteButton)`
  border: 2px solid #000;
  padding: 10px 30px;
  margin: 0 12px;
  border-radius: 30px;
`;

type Props = {
  itemId: number;
  video: VideoType;
};

export function Card({ video }: Props) {
  const setGlobalDialog = useTmpStore((store) => store.setGlobalDialog);

  const { data: nftBalance } = useNftOwnershipInfo({
    contractAddress: video.contractAddress,
    tokenIds: video.tokenIds,
  });

  const { makeAndSignChallenge, videoUrl, reset } = useSignVideo(video._id);
  const userHasNft = nftBalance.gt(0);
  const unlocked = userHasNft || !video.contractAddress;

  return (
    <CardContainer
      imageURL={`${serverURL}/thumbnails/${video.thumbnailURL}`}
      onClick={async () => {
        if (!unlocked) {
          setGlobalDialog({
            body: (
              <ErrorContent>
                <LockImageBlock>
                  <LockImage src={"/images/lock-front-gradient.png"} />
                </LockImageBlock>
                Access to this content{" "}
                <b>
                  requires ownership of a specific NFT in your connected wallet.
                </b>
                <ErrorButtons>
                  <WhiteButtonWithBorder onClick={() => setGlobalDialog(null)}>
                    Cancel
                  </WhiteButtonWithBorder>
                  <WhiteButtonWithBorder
                    inverted={true}
                    onClick={() => {
                      invariant(typeof video.release !== "string");
                      invariant(typeof video.release.production !== "string");

                      if (!video.release.production.purchaseURL) {
                        return;
                      }

                      window.open(
                        video.release.production.purchaseURL,
                        "_blank"
                      );
                    }}
                  >
                    Get NFT <Icon size={22} />
                  </WhiteButtonWithBorder>
                </ErrorButtons>
              </ErrorContent>
            ),
          });
          return;
        }

        await makeAndSignChallenge(!video.contractAddress);
      }}
    >
      {videoUrl && <VideoDisplay token={videoUrl} onClose={() => reset()} />}

      <div />

      <BottomContainer>
        {unlocked ? (
          <Title>{video.title}</Title>
        ) : (
          <Button>
            Get Access <Icon size={18} />
          </Button>
        )}
        <div>
          {unlocked ? (
            <FaUnlock size={18} color={"#FFF"} />
          ) : (
            <FaLock size={18} color={"#FFF"} />
          )}
        </div>
      </BottomContainer>
    </CardContainer>
  );
}
